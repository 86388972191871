




















































import { Component, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AddAddressForm from '@/modules/MyRoomsPage/components/AddAddressForm.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import axios from '@/api/axios'

interface yMapSettings {
  coords: number[]
  zoom: number
}

export interface Model {
  name: string
  city: null | number
  address: string
  tel: string
  parkingInfo?: string
  wayDirections?: string
  metroStations?: null | number
  active: boolean
}

@Component({
  name: 'AddressForm',
  components: {
    BaseIcon,
    BaseButton,
    AddAddressForm,
    yandexMap,
    ymapMarker,
  },
})
export default class AddressForm extends Vue {
  model: Model = {
    name: '',
    city: null,
    address: '',
    tel: '',
    parkingInfo: '',
    active: false,
  }

  error = ''

  yMapSettings: yMapSettings = {
    coords: [55.755819, 37.617644],
    zoom: 10.5,
  }

  public async createAddress(): Promise<void> {
    try {
      await axios.post('/locations', {
        active: this.model.active,
        name: this.model.name,
        parking_info: this.model.parkingInfo,
        way_directions: this.model.wayDirections,
        address: this.model.address,
        phone: this.model.tel,
        gps_coords: {
          lat: this.yMapSettings.coords[0],
          lng: this.yMapSettings.coords[1],
        },
        city_id: this.model.city,
        metro_stations: [this.model.metroStations],
      })

      this.$emit('close')
    } catch (e) {
      this.error = 'Во время отправки формы произошла ошибка'
      console.log(e)
    }
  }

  public submit(): void {
    this.error = ''

    if ((this.$refs.form as any).checkValidity()) {
      this.createAddress()
    }
  }

  public clickToMap(e: any): void {
    this.yMapSettings = {
      ...this.yMapSettings,
      coords: e.get('coords'),
    }
  }
}
