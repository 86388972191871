

















































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
const StatististicsStore = namespace('StatististicsStore')

import { RoomModel } from './models/RoomModel'

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Chart from '@/components/Chart.vue'
import AddRoom from './components/AddRoom.vue'
import Room from './components/Room.vue'
import RoomForm from './components/RoomForm.vue'
import AddressForm from './components/AddressForm.vue'
import IntegrationForm from './components/IntegrationForm.vue'
import ConditionForm from './components/ConditionForm.vue'

@Component({
  name: 'MyRooms',
  components: {
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    AddRoom,
    Room,
    RoomForm,
    AddressForm,
    IntegrationForm,
    Chart,
    ConditionForm,
  },
})
export default class MyRoomsPage extends Vue {
  openForm = false
  isAddressFormVisible = false
  isIntegrationFormVisible = false
  isConditionFormVisible = false
  conditionForEdit = null

  @StatististicsStore.Getter
  public statististicsList!: any

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  @RoomsStore.Action
  private getRooms!: () => Promise<RoomModel[]>

  @StatististicsStore.Action
  private getStatististics!: () => Promise<any>

  public created(): void {
    this.getRooms()
    this.getStatististics()
  }

  addRoom(): void {
    this.openForm = true
    this.isAddressFormVisible = false
    this.isIntegrationFormVisible = false
    this.isConditionFormVisible = false
  }

  showAddressForm(): void {
    this.isAddressFormVisible = true
    this.openForm = false
    this.isIntegrationFormVisible = false
  }

  showIntegrationForm(): void {
    this.isAddressFormVisible = false
    this.openForm = false
    this.isIntegrationFormVisible = true
  }

  showConditionForm(): void {
    this.conditionForEdit = null
    this.isConditionFormVisible = true
    this.openForm = false
  }

  editCondition(payload: any): void {
    this.conditionForEdit = payload
    this.isConditionFormVisible = true
    this.openForm = false
  }
}
