

































































































































import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { namespace } from 'vuex-class'
import { Condition, Integration, Model } from '@/store/modules/room.module'

const StepOne = () => import('./RoomForm/StepOne.vue')
const StepTwo = () => import('./RoomForm/StepTwo.vue')
const StepThree = () => import('./RoomForm/StepThree.vue')
const StepFour = () => import('./RoomForm/StepFour.vue')
const StepFive = () => import('./RoomForm/StepFive.vue')

const RoomStore = namespace('RoomStore')

@Component({
  name: 'RoomForm',
  components: {
    BaseButton,
    BaseIcon,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
  },
})
export default class RoomForm extends Vue {
  images: File[] = []
  activeButton = 5
  more = false
  buttons = [
    { id: 1, title: 'Описание', visible: true, disabled: false },
    { id: 2, title: 'Календарь', visible: true, disabled: true },
    { id: 3, title: 'Опции', visible: true, disabled: true },
    { id: 4, title: 'Фото', visible: true, disabled: true },
    { id: 5, title: 'Цены', visible: true, disabled: true },
  ]

  @RoomStore.State('model')
  model!: Model

  @RoomStore.State('integrations')
  integrations!: Integration[]

  @RoomStore.State('conditions')
  conditions!: Condition[]

  @RoomStore.Mutation('setModel')
  setModel!: (model: Model) => void

  @RoomStore.Action('createPlace')
  createPlace!: () => void

  get currentCount(): number {
    const arr = this.buttons.filter((button) => button.visible)
    return arr.length
  }

  get currentComponent(): string {
    switch (this.activeButton) {
      case 2:
        return 'StepTwo'
      case 3:
        return 'StepThree'
      case 4:
        return 'StepFour'
      case 5:
        return 'StepFive'
      default:
        return 'StepOne'
    }
  }

  checkValidity(): boolean {
    return (this.$refs[this.currentComponent] as any)?.checkValidity?.() ?? true
  }

  update<T>(data: T): void {
    this.setModel({
      ...this.model,
      [this.currentComponent]: data,
    })
  }

  next(): void {
    if (
      this.checkValidity() &&
      this.activeButton <= this.currentCount &&
      this.activeButton < 5
    ) {
      let nextstep: any = this.buttons.find(
        (button) => button.id === this.activeButton + 1
      )
      nextstep.disabled = false
      this.activeButton += 1
    }
    if (this.checkValidity() && this.activeButton === 5) {
      this.createPlace()
    }
  }

  changeImages(images: File[]): void {
    this.images = images
  }
}
